// ** MOBILE/SMALL SCREEN VERTICAL TEXT CAROUSEL ON HOME PAGE
import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { screen, mainRed, lightBlue, darkBlue } from "../common/variables";
import { TiTimes } from "react-icons/ti";

const Wrapper = styled.div`
  text-align: center;
  margin-top: 25px;
  @media ${screen.xSmall} {
    margin-top: 40px;
  }
  @media ${screen.large} {
    display: none;
  }

  .title {
    color: ${darkBlue};
    font-size: 1.01rem;
    margin-bottom: 12px;
    padding-left: 35px;
    padding-right: 35px;
    @media ${screen.xSmall} {
      font-size: 1.2rem;
      margin-bottom: 15px;
      padding-left: 0;
      padding-right: 0;
    }
    @media ${screen.small} {
      font-size: 1.5rem;
      margin-bottom: 18px;
    }

    span {
      text-decoration: underline;
    }
  }

  .slick-list {
    padding: 0 !important;
  }

  .slick-track {
    .slick-slide {
      transform: scale(0.7);
      transition-duration: 0.3s;
      transition-delay: 0.28s;
      h2 {
        font-size: 1.3rem;
        padding: 1px 0;
        color: ${lightBlue};
        display: inline-block;
        @media ${screen.xxXSmall} {
          font-size: 1.4rem;
        }
        @media ${screen.xSmall} {
          font-size: 2.2rem;
          padding: 2px 0;
        }
        @media ${screen.small} {
          font-size: 2.8rem;
          padding: 3px 0;
        }
      }
      .x-icon {
        color: ${lightBlue};
        font-size: 1.9rem;
        vertical-align: bottom;
        margin-bottom: 2px;
        @media ${screen.small} {
          font-size: 3.2rem;
          margin-bottom: 9px;
        }
      }
    }

    .slick-current,
    .slick-center {
      transform: scale(1);
      h2 {
        color: ${mainRed};
      }

      .x-icon {
        color: ${mainRed};
        font-size: 2.1rem;
        margin-bottom: 0;
        @media ${screen.small} {
          font-size: 3.7rem;
          margin-bottom: 6px;
        }
      }
    }
  }
`;

const SmScreenTextsCarousel = ({ signs, className }) => {
  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    pauseOnHover: false,
    autoplay: true,
    speed: 1000,
    swipe: false,
    autoplaySpeed: 1700,
  };
  return (
    <Wrapper className={className}>
      <h2 className="title">
        Some signs your practice <span>isn’t</span> a business…
      </h2>
      <Slider {...settings}>
        {signs.map((sign) => (
          <React.Fragment key={sign.text}>
            <TiTimes className="x-icon" /> <h2>{sign.text}</h2>
          </React.Fragment>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default SmScreenTextsCarousel;
