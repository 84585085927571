import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Slider from "react-slick";
import SmScreenTextsCarousel from "../components/sm-screen-texts-carousel";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  lightBlue,
  darkBlue,
  mainWhite,
  mainRed,
  screen,
  Subtitle,
  StyledLink,
  StyledA,
  InnerWrapper,
  homeScreen,
} from "../components/common/variables";
import {
  movingDash,
  movingDashLong,
  bobDown,
} from "../components/common/animations";
import davidBgLarge from "../images/global-adviser-alpha-david-large.png";
import davidBgMobile from "../images/david-haintz-global-adviser-alpha.png";
import davidBgTablet from "../images/david-haintz.png";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { TiTimes } from "react-icons/ti";
import { GatsbyImage } from "gatsby-plugin-image";
import BannerDashed1 from "../components/assets/dashed-lines/home2.svg";
import SignsDashed1 from "../components/assets/dashed-lines/home3.svg";
import SignsDashed2 from "../components/assets/dashed-lines/home4.svg";
import ServicesDashed1 from "../components/assets/dashed-lines/home5.svg";
import ServicesDashed2 from "../components/assets/dashed-lines/home6.svg";
import ServicesDashed3 from "../components/assets/dashed-lines/home7.svg";
import RoadmapDashed1 from "../components/assets/dashed-lines/home8.svg";
import Carousel from "../components/carousel";
import ProgramsIcon from "../components/assets/services/programs-icon.svg";
import AdviceIcon from "../components/assets/services/advice-icon.svg";
import VisionIcon from "../components/assets/services/vision-icon.svg";
import { Waypoint } from "react-waypoint";

const Wrapper = styled.div`
  background-color: ${mainWhite};

  .home-banner {
    position: relative;
    height: 530px;
    background-color: ${lightBlue};
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${davidBgMobile});
    @media ${screen.xXSmall} {
      height: 700px;
      background-image: url(${davidBgTablet});
    }
    @media ${homeScreen.medium} {
      background-image: url(${davidBgLarge});
    }
    @media ${homeScreen.large} {
      background-image: url(${davidBgLarge});
      height: 100vh;
    }
    @media ${homeScreen.largeTall} {
      background-image: url(${davidBgLarge});
      height: 1040px;
    }

    .title-right {
      position: absolute;
      display: none;
      @media ${homeScreen.largeTall} {
        display: block;
        right: 7%;
        bottom: 139px;
      }
      @media ${homeScreen.xLargeTall} {
        right: 132px;
        bottom: 125px;
      }
      @media ${homeScreen.xXLargeTall} {
        right: 173px;
        bottom: 100px;
      }

      @media ${homeScreen.medium} {
        display: block;
        right: 4%;
        bottom: 45px;
      }
      @media ${homeScreen.large} {
        display: block;
        right: 7%;
        bottom: 88px;
      }
      @media ${homeScreen.xLarge} {
        right: 5%;
        bottom: 75px;
      }
      @media ${homeScreen.xXLarge} {
        right: 8.8%;
        bottom: 70px;
      }
      @media ${homeScreen.xxXLarge} {
        right: 5%;
      }

      .banner-dashed-line1 {
        display: block;
        margin-left: 217px;
        width: 84px;
        width: 112px;
        margin-top: 5px;
        margin-bottom: 10px;
        @media ${homeScreen.xLarge} {
          margin-left: 268px;
        }
        @media ${homeScreen.xXLarge} {
          margin-left: 304px;
        }
        @media ${homeScreen.largeTall} {
          margin-left: 292px;
        }
        @media ${homeScreen.xLargeTall} {
          margin-left: 355px;
        }
        @media ${homeScreen.xXLargeTall} {
          margin-left: 323px;
        }
        @media ${homeScreen.medium} {
          margin-left: 264px;
        }
        @media ${homeScreen.xLarge} {
          margin-left: 254px;
        }
        @media ${homeScreen.xXLarge} {
          margin-left: 310px;
        }

        .dashed {
          stroke-dasharray: 12 16;
        }
        .path {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: ${movingDash} 7s ease-out forwards;
        }
      }

      .site-title {
        font-size: 4rem;
        color: ${mainWhite};
        font-weight: 900;
        @media ${homeScreen.medium} {
          font-size: 3.15rem;
        }
        @media ${homeScreen.large} {
          font-size: 3.6rem;
        }
        @media ${homeScreen.xLarge} {
          font-size: 4rem;
        }
        @media ${homeScreen.xXLarge} {
          font-size: 4.2rem;
        }
        @media ${homeScreen.largeTall} {
          font-size: 3.8rem;
        }
        @media ${homeScreen.xLargeTall} {
          font-size: 4.25rem;
        }
        @media ${homeScreen.xXLargeTall} {
          font-size: 4.8em;
        }
      }

      &--inline {
        color: ${darkBlue};
        display: inline-block;
        font-weight: 300;
        font-size: 2.6rem;
        margin-left: 5px;
        @media ${homeScreen.medium} {
          font-size: 2.2rem;
        }
      }
    }

    .scroll-icon {
      color: ${mainWhite};
      display: block;
      margin-top: 8px;
      font-size: 1.5rem;
      animation: ${bobDown} 1s infinite;
      transition: opacity 0.5s ease-out;
      @media ${homeScreen.medium} {
        font-size: 1.2rem;
        margin-left: 212px;
      }

      @media ${homeScreen.largeTall} {
        margin-left: 282px;
      }
      @media ${homeScreen.xLargeTall} {
        margin-left: 343px;
      }
      @media ${homeScreen.xXLargeTall} {
        margin-left: 312px;
      }

      @media ${homeScreen.medium} {
        margin-left: 256px;
      }
      @media ${homeScreen.xLarge} {
        margin-left: 246px;
      }
      @media ${homeScreen.xXLarge} {
        margin-left: 302px;
      }
    }
  }

  .banner-text-sm-screen {
    background: rgba(6, 167, 226, 0.7);
    position: absolute;
    bottom: 34px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 35px;
    width: 100%;
    @media ${screen.xXSmall} {
      bottom: 52px;
      padding-top: 22px;
      padding-bottom: 22px;
      padding-left: 11%;
    }
    @media ${homeScreen.medium} {
      display: none;
    }
    @media ${homeScreen.large} {
      display: none;
    }
    @media ${homeScreen.largeTall} {
      display: none;
    }

    &__title {
      color: ${mainWhite};
      font-size: 1.85rem;
      font-weight: 900;
      line-height: 1.3;
      @media ${screen.xxXSmall} {
        font-size: 2.3rem;
      }
      @media ${screen.xXSmall} {
        font-size: 3rem;
      }

      &--dark {
        font-weight: 700;
        font-size: 1.7rem;
        display: block;
        @media ${screen.xxXSmall} {
          font-size: 2.2rem;
        }
        @media ${screen.xXSmall} {
          font-size: 2.8rem;
        }
      }

      &--white {
        color: ${mainWhite};
        font-weight: 900;
        font-size: 1.7rem;
        @media ${screen.xxXSmall} {
          font-size: 2.2rem;
        }
        @media ${screen.xXSmall} {
          font-size: 2.8rem;
        }
      }

      &--p1 {
        display: block;
        text-align: center;
      }

      &--last-line {
        display: block;
        max-width: 430px;
        margin: 0 auto;
      }
    }
  }

  .text-carousel-mobile {
    min-height: 200px;
  }

  .signs-container {
    padding-top: 0;
    position: relative;
    margin-top: -10px;
    @media ${screen.xSmall} {
      padding-top: 0;
      margin-top: -60px;
    }
    @media ${screen.large} {
      padding-top: 160px;
      margin-top: 0;
    }

    &__signs-dashed-line-1 {
      width: 130px;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 227px;
      display: none;
      @media ${screen.large} {
        display: block;
      }
      @media ${homeScreen.large} {
        margin-right: 185px;
        margin-top: 12px;
      }
      @media ${homeScreen.xLarge} {
        margin-right: 240px;
        margin-top: 12px;
      }
      @media ${homeScreen.xXLarge} {
        margin-right: 277px;
      }
      @media ${homeScreen.largeTall} {
        margin-right: 188px;
        margin-top: 12px;
      }
      @media ${homeScreen.xLargeTall} {
        margin-right: 224px;
      }
      @media ${homeScreen.xXLargeTall} {
        margin-right: 371px;
      }

      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 5s 0.5s ease-out forwards;
      }
    }

    &__title {
      color: ${darkBlue};
      text-align: right;
      font-size: 1.5rem;
      margin-bottom: 20px;

      .underlined {
        text-decoration: underline;
      }
    }

    &__slider-container {
      position: relative;
      z-index: 0;
      display: none;
      @media ${screen.large} {
        display: block;
        min-height: 470px;
      }

      &__signs-dashed-line-2 {
        color: ${darkBlue};
        font-size: 1.8rem;
        position: absolute;
        width: 1139px;
        top: 432px;
        margin-left: 50px;
        display: none;

        @media ${screen.medium} {
          top: 392px;
          width: 1080px;
          margin-left: 31px;
        }
        @media ${screen.large} {
          display: block;
          width: 1139px;
          top: 260px;
        }
        @media ${screen.xLarge} {
          margin-left: 50px;
          top: 295px;
        }

        .dashed {
          stroke-dasharray: 12 16;
        }
        .path {
          stroke-dasharray: 1900;
          stroke-dashoffset: 1900;
          animation: ${movingDashLong} 2.1s ease-in forwards;
        }
      }

      .slick-slider {
        z-index: 2;
        background-image: linear-gradient(to right, transparent 71%, #fff 29%);
      }

      .slick-list {
        padding: 0 !important;
      }

      .slick-slide {
        border: none;
        transform: scale(0.8);
        transition: all 0.3s 0.28s ease-out;
        transform-origin: 100% 50% 0;
        opacity: 0.53;
        transform: scale(00.58);

        h2 {
          color: ${lightBlue};
          font-size: 3.4rem;
          position: relative;
          z-index: 99999;
          background: ${mainWhite};
          text-align: center;
          padding-left: 50px;
          transform: translateX(-125px);
          transition: all 0.3s 0.28s ease-out;
          @media ${screen.xLarge} {
            font-size: 4rem;
          }
          svg {
            font-size: 5.3rem;
            position: absolute;
            left: -44px;
            background: ${mainWhite};
            @media ${screen.xLarge} {
              font-size: 6rem;
            }
          }
        }
      }

      .slick-current,
      .slick-center {
        transform: scale(1);
        opacity: 1;

        h2 {
          color: ${mainRed};
          transform: translateX(0);
        }
      }
    }

    &__each-slider {
      width: auto !important;
      display: flex !important;
      outline: none;
      justify-content: flex-end;
    }

    &__content-img-container {
      display: flex;
      padding-top: 30px;
      flex-direction: column;

      @media ${screen.xSmall} {
        justify-content: space-between;
        flex-direction: row;
        padding-top: 90px;
      }
    }

    &__content {
      max-width: 622px;
      margin-right: 0;
      text-align: center;
      margin-top: 14px;
      @media ${screen.xSmall} {
        margin-right: 35px;
      }
      @media ${screen.small} {
        text-align: left;
        margin-top: 45px;
      }
      @media ${screen.large} {
        margin-top: 90px;
      }

      h4 {
        color: ${darkBlue};
        font-size: 1.15rem;
        margin-bottom: 14px;
        font-weight: 400;
        @media ${screen.small} {
          margin-bottom: 30px;
        }
        @media ${screen.large} {
          font-size: 1.35rem;
        }

        strong {
          font-weight: 900;
        }
      }

      p {
        color: ${darkBlue};
        font-size: 1rem;
        @media ${screen.large} {
          font-size: 1.1rem;
        }
      }
    }

    &__img {
      max-width: 462px;
      width: 100%;
      order: -1;
      margin: 0 auto;
      @media ${screen.xSmall} {
        margin: 0;
        order: 1;
      }
      @media ${screen.medium} {
        max-width: 370px;
      }
      @media ${screen.xLarge} {
        max-width: 462px;
      }
    }
  }

  .services-container {
    padding-bottom: 30px;

    &__head-text {
      color: ${lightBlue};
      text-align: center;
      font-size: 1.1rem;
      position: relative;
      margin: 45px 0;
      @media ${screen.medium} {
        font-size: 1.5rem;
        text-align: left;
        margin: 90px 0 115px 5px;
      }
      @media ${screen.large} {
        padding-left: 400px;
      }
    }

    &__services-dashed-line-1 {
      width: 416px;
      position: absolute;
      bottom: 15px;
      margin-left: 30px;
      display: none;
      @media ${screen.large} {
        display: inline-block;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 2.5s ease-out forwards;
      }
    }

    &__services-dashed-line-2 {
      width: 338px;
      position: absolute;
      left: 45px;
      top: 18px;
      display: none;
      @media ${screen.large} {
        display: inline-block;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 2.2s 1s ease-out forwards;
      }
    }

    &__services-dashed-line-3 {
      width: 100px;
      margin-left: 50px;
      margin-top: 20px;
      margin-bottom: 20px;
      display: none;
      @media ${screen.large} {
        display: block;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 3.8s 0.4s ease-out forwards;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      @media ${screen.medium} {
        flex-wrap: nowrap;
      }
      @media ${screen.medium} {
        justify-content: flex-start;
      }

      .services {
        max-width: 280px;
        margin-right: 0;
        margin-bottom: 52px;
        text-align: center;
        overflow: hidden;
        @supports (flex-direction: column) {
          flex-direction: column;
          display: flex;
        }
        &:last-child {
          margin-right: 0;
        }
        @media ${screen.xSmall} {
          max-width: 210px;
          align-items: flex-start;
          text-align: left;
          margin-bottom: 90px;
          margin-right: 25px;
        }
        @media ${screen.medium} {
          margin-bottom: 0;
          margin-right: 80px;
          max-width: 270px;
        }

        .icons {
          width: 90px;
          height: 90px;
          margin: 0 auto;
          @media ${screen.medium} {
            width: 120px;
            height: 120px;
            margin: 0;
          }
        }

        h2 {
          color: ${darkBlue};
          font-size: 1.3rem;
          margin: 10px 0 10px 0;
          text-align: center;
          @media ${screen.medium} {
            text-align: left;
            font-size: 1.5rem;
            margin: 30px 0 20px 0;
          }
        }

        p {
          margin-bottom: 20px;
          color: ${darkBlue};
          font-size: 1rem;
          @media ${screen.medium} {
            margin-bottom: 30px;
          }
          @media ${screen.large} {
            font-size: 1.1rem;
          }
        }

        a {
          margin-top: auto;
          width: 100%;
          text-align: center;
          @media ${screen.small} {
            width: auto;
          }
        }
      }
    }

    &__bottom-text {
      color: ${lightBlue};
      font-size: 1.1rem;
      text-align: center;
      @media ${screen.medium} {
        font-size: 1.5rem;
        margin-top: 90px;
        text-align: left;
      }
      @media ${screen.large} {
        margin-top: 0;
        font-size: 1.5rem;
      }
    }
  }

  .roadmap-container {
    position: relative;
    padding: 45px 0 45px 0;
    @media ${screen.medium} {
      padding: 90px 0 90px 0;
    }
    @media ${screen.large} {
      padding: 160px 0 90px 0;
    }

    &__title {
      text-align: center;
      @media ${screen.xSmall} {
        text-align: left;
      }
    }

    .inner-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &__img {
      width: 100%;
      margin: 0 auto;
      max-width: 170px;
      order: 1;
      margin: 20px auto 0 auto;
      filter: drop-shadow(-10px 10px 7px rgba(000, 000, 000, 0.4));
      @media ${screen.small} {
        order: -1;
        max-width: 140px;
        margin: 0 auto 45px auto;
      }

      @media ${screen.medium} {
        margin: 0 auto 20px auto;
        max-width: 300px;
      }
      @media ${screen.large} {
        margin: 0 0 0 30px;
      }
    }

    &__texts {
      margin: 0 auto;
      align-self: center;
      max-width: 520px;
      @media ${screen.medium} {
        margin: 0 auto;
        max-width: 590px;
      }
      @media ${screen.large} {
        margin: 0 0 0 120px;
      }

      p {
        color: ${darkBlue};
        font-size: 1rem;
        margin-top: 10px;
        margin-bottom: 20px;
        @media ${screen.large} {
          font-size: 1.1rem;
        }
      }

      a {
        width: 100%;
        text-align: center;
        @media ${screen.xSmall} {
          width: auto;
        }
      }
    }

    &__roadmap-dashed-1 {
      width: 100px;
      position: absolute;
      top: 10px;
      margin-left: 50px;
      display: none;
      @media ${screen.large} {
        display: block;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 5s ease-out forwards;
      }
    }
  }
`;

const settings = {
  dots: false,
  arrows: false,
  centerMode: true,
  focusOnSelect: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  pauseOnHover: false,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 1700,
  vertical: true,
};

const IndexPage = ({ data }) => {
  const titleTag = data.content.data.title_tag;
  const metaDescription = data.content.data.meta_description;
  const signs = data.content.data.bad_practice_signs;
  const signsDescription = data.content.data.bad_practice_sign_description.html;
  const roadMapDescription = data.content.data.description;
  const signsImg =
    data.content.data.bad_practice_sign_featured_image.gatsbyImageData;
  const bookImg = data.content.data.featured_image.gatsbyImageData;

  // State variables just for hide/show an element.
  const [isScrolled, setIsScrolled] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const [signsDashed1And2IsShowing, setSignsDashed1And2IsShowing] =
    useState(false);
  const [servicesDashed1And2IsShowing, setServicesDashed1And2IsShowing] =
    useState(false);
  const [servicesDashed3IsShowing, setServicesDashed3IsShowing] =
    useState(false);
  const [roadmapDashed1IsShowing, setRoadmapDashed1IsShowing] = useState(false);

  // Hide the scroll arrow when set to true.
  const isScrollingDown = () => {
    setIsScrolled(true);
  };

  // Show the scroll arrow when set to false.
  const isScrollingUp = () => {
    setIsScrolled(false);
  };

  // Dashed lines start drawing once set to true. Functions will be called by Waypoint.
  const showSignsDashed1And2 = () => {
    setSignsDashed1And2IsShowing(true);
  };

  const showServicesDashed1And2 = () => {
    setServicesDashed1And2IsShowing(true);
  };

  const showServicesDashed3 = () => {
    setServicesDashed3IsShowing(true);
  };

  const showRoadmapDashed1 = () => {
    setRoadmapDashed1IsShowing(true);
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <Waypoint
          onEnter={isScrollingDown}
          onLeave={isScrollingUp}
          topOffset="270px"
        >
          <header className="home-banner">
            <div className="title-right">
              <h1 className="site-title">
                <span style={{ fontWeight: 400 }}>Clients win.</span>
                <br />
                <span style={{ fontWeight: 400 }}>Advisers win.</span>
                <br />
                We show you how.
              </h1>
              <BannerDashed1 className="banner-dashed-line1" />
              <FaChevronDown
                className="scroll-icon"
                style={{ opacity: isScrolled ? 1 : 0 }}
              />
            </div>

            <div className="banner-text-sm-screen">
              <h1 className="banner-text-sm-screen__title">
                <span style={{ fontWeight: 400 }}>Clients win.</span>
                <br />
                <span style={{ fontWeight: 400 }}>Advisers win.</span> <br />
                We show you how.
              </h1>
            </div>
          </header>
        </Waypoint>
        {isClient && (
          <SmScreenTextsCarousel
            className="text-carousel-mobile"
            signs={signs}
          />
        )}

        <div className="signs-container">
          <InnerWrapper>
            <div className="signs-container__signs-dashed-line-1">
              {signsDashed1And2IsShowing ? <SignsDashed1 /> : null}
            </div>
            <div className="signs-container__slider-container">
              <Waypoint onEnter={showSignsDashed1And2} topOffset="200px">
                <h2 className="signs-container__title">
                  Some signs your practice{" "}
                  <span className="underlined">isn't</span> a business...
                </h2>
              </Waypoint>
              <div className="signs-container__slider-container__signs-dashed-line-2">
                {signsDashed1And2IsShowing ? <SignsDashed2 /> : null}
              </div>

              {isClient && (
                <Slider {...settings}>
                  {signs.map((sign, i) => {
                    return (
                      <div
                        key={sign.text}
                        className="signs-container__each-slider"
                      >
                        <h2 className={`sign-${i}`}>
                          <TiTimes />
                          {sign.text}
                        </h2>
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>

            <main className="signs-container__content-img-container">
              <div
                className="signs-container__content"
                dangerouslySetInnerHTML={{ __html: signsDescription }}
              />
              <div className="signs-container__img">
                <GatsbyImage
                  image={signsImg}
                  alt="signs your practice isn't a business"
                />
              </div>
            </main>
          </InnerWrapper>
        </div>

        <div className="services-container">
          <InnerWrapper>
            <Waypoint onEnter={showServicesDashed1And2} topOffset="100px">
              <h2 className="services-container__head-text">
                {servicesDashed1And2IsShowing ? (
                  <ServicesDashed2 className="services-container__services-dashed-line-2" />
                ) : null}
                How David can help
                {servicesDashed1And2IsShowing ? (
                  <ServicesDashed1 className="services-container__services-dashed-line-1" />
                ) : null}
              </h2>
            </Waypoint>
            <div className="services-container__list">
              <div className="services">
                <VisionIcon className="icons" />
                <h2>Inspire Change</h2>
                <p>
                  Speaking engagements where we lay out the potential of global
                  best practice transformation, with wins for all stakeholders.
                </p>
                <StyledLink to="/inspire-change">
                  FIND OUT MORE <FaChevronRight />
                </StyledLink>
              </div>

              <div className="services">
                <ProgramsIcon className="icons" />
                <h2>Our Program</h2>
                <p>
                  Transform your business with our leading global comprehensive
                  coaching and mentoring program for owners, practice managers,
                  and advisers – online or facilitated.
                </p>
                <StyledLink to="/programs">
                  FIND OUT MORE <FaChevronRight />
                </StyledLink>
              </div>
              <div className="services">
                <AdviceIcon className="icons" />
                <h2>Tailored Advice</h2>
                <p>
                  A full consultancy designed specifically for your firm. This
                  could be holistic such as an advisory board role, or project
                  specific.
                </p>
                <StyledLink to="/tailored-advice">
                  FIND OUT MORE <FaChevronRight />
                </StyledLink>
              </div>
            </div>

            {servicesDashed3IsShowing ? (
              <ServicesDashed3 className="services-container__services-dashed-line-3" />
            ) : null}
            <Waypoint onEnter={showServicesDashed3}>
              <h2 className="services-container__bottom-text">
                Want proof of success?
              </h2>
            </Waypoint>
          </InnerWrapper>
        </div>

        <Carousel testimonials={data.testimonials.edges} />

        <div className="roadmap-container">
          <InnerWrapper className="inner-wrapper">
            {roadmapDashed1IsShowing ? (
              <RoadmapDashed1 className="roadmap-container__roadmap-dashed-1" />
            ) : null}

            <div className="roadmap-container__img">
              <a
                href="https://www.life-firstadvisor.com/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to The Life-First Advisor Book page."
              >
                <GatsbyImage image={bookImg} alt="The Life-First Advisor" />
              </a>
            </div>

            <Waypoint onEnter={showRoadmapDashed1} bottomOffset="200px">
              <div className="roadmap-container__texts">
                <Subtitle className="roadmap-container__title" dark>
                  Here's your roadmap
                </Subtitle>
                <p>{roadMapDescription}</p>
                <StyledA
                  href="https://www.life-firstadvisor.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FIND OUT MORE <FaChevronRight />
                </StyledA>
              </div>
            </Waypoint>
          </InnerWrapper>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const data = graphql`
  {
    content: prismicHomePage {
      data {
        title_tag
        meta_description
        description
        bad_practice_signs {
          text
        }
        bad_practice_sign_description {
          html
        }
        bad_practice_sign_featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    testimonials: allPrismicTestimonial(
      filter: { data: { is_program_display_page: { eq: false } } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          id
          data {
            full_name {
              text
            }
            company {
              text
            }
            occupation_title {
              text
            }
            photo {
              url
            }
            message {
              text
            }
          }
        }
      }
    }
  }
`;
